import { FlagsProvider } from "flagged"
import { useEffect, useState } from "react"
import { ThemeProvider as JSSThemeProvider } from "react-jss"
import { useDispatch, useSelector } from "react-redux"
import { useSWRConfig } from "swr"
import { AbilityContext, createAbility } from "./casl/ability"
import Toaster from "./components/Toaster"
import ZtnaRoutes from "./routes"
import { AuthRoutesMap, END_USER_BASE_ROUTE_PREFIX, PublicRoutesMap, Roles } from "./routes/config"
import useAppManifest from "./services/api/swrHooks/useAppManifest"
import useEnvironmentDetails from "./services/api/swrHooks/useEnvironmentDetails"
import logger from "./services/logger"
import { RootState } from "./store"
import { resetAuth, setUser } from "./store/auth"
import { openSideNav } from "./store/ui/uiSlice"
import theme from "./theme"
import { getXiqUrl } from "./utils"
import { FEATURE_FLAGS_LOCAL_STORAGE_KEY } from "./utils/constants"

const App: React.FC = () => {
  const dispatch = useDispatch()
  // const [renderRoutes, setRenderRoutes] = useState(false)
  const user = useSelector((state: RootState) => state.auth.user)
  const { toasterState, featureFlags } = useSelector((state: RootState) => state.ui)
  // const { data: userInfoForDRAP, isLoading, error } = useUserInfoForDRAP()
  const isSideNavOpened = navigator.cookieEnabled ? localStorage.getItem("isSideNavOpened") === "true" : false
  const { cache } = useSWRConfig()
  const { data } = useAppManifest()
  const { data: environmentDetails, error: envDetailsError } = useEnvironmentDetails()

  useEffect(() => {
    isSideNavOpened && dispatch(openSideNav())
  }, [])

  const [ability, seAbility] = useState(createAbility(user.role))

  useEffect(() => {
    const newAbilty = createAbility(user.role)
    seAbility(newAbilty)
  }, [user.role])

  useEffect(() => {
    if (environmentDetails && !envDetailsError) {
      sessionStorage.setItem("environmentDetails", environmentDetails.environmentUrl)
      localStorage.setItem("xiqUrl", environmentDetails?.xiqUrl)
      localStorage.setItem("xcdUrl", environmentDetails?.xcdUrl)
      localStorage.setItem("xiqLogoutUrl", environmentDetails?.xiqLogoutUrl)
      localStorage.setItem("cloudHost", environmentDetails?.cloudHost)
    }
  }, [environmentDetails])

  useEffect(() => {
    if (data?.version && data?.version !== "undefined" && data?.version !== "null") {
      localStorage.setItem("UAAV", process.env.NODE_ENV === "development" ? "1.0.0" : data?.version)
    }
  }, [data?.version])
  // const updateUserDataInLS = () => {
  //   if (userInfoForDRAP) {
  //     const { email, role, sessionId } = userInfoForDRAP
  //     const loggedInUser = {
  //       email,
  //       role: role.code,
  //       sessionId,
  //       name: `${userInfoForDRAP?.firstName} ${userInfoForDRAP?.lastName}`,
  //       workspace: userInfoForDRAP?.workspace.workspaceUrl,
  //       workspaceId: userInfoForDRAP?.workspace.id,
  //     }
  //     dispatch(setUser(loggedInUser))
  //     localStorage.setItem("userData", JSON.stringify(loggedInUser))
  //     if (role.code === "tenant_end_user") {
  //       localStorage.setItem("sessionId", JSON.stringify(sessionId))
  //     }
  //     // Render Routes Component after setting userData in LS
  //     // to avoid redirection to Login page for DRAP.
  //     setRenderRoutes(true)
  //   }
  // }

  // useEffect(() => {
  //   if (userInfoForDRAP) {
  //     updateUserDataInLS()
  //   }
  //   if (!userInfoForDRAP && !isLoading) {
  //     setRenderRoutes(true)
  //   }
  // }, [userInfoForDRAP, error])

  const resetUserData = (localRole: string, currentPath: string) => {
    logger.flush()
    ;(cache as any).clear()
    let route = ""
    if (localRole === Roles.SUPER_ADMIN) {
      route = AuthRoutesMap.ZTNA_ADMIN_LOGIN.absolutePath
    } else {
      route = AuthRoutesMap.LOGIN.absolutePath
    }

    localStorage.removeItem("userData")
    localStorage.removeItem("isSideNavOpened")
    localStorage.removeItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY)
    dispatch(resetAuth())
    if (localRole === Roles.SUPER_ADMIN || localRole === Roles.END_USER) {
      window.location.href = `${window.location.origin}${route}`
    } else if (currentPath.includes(END_USER_BASE_ROUTE_PREFIX)) {
      window.location.href = `${window.location.origin}${AuthRoutesMap.LOGIN.absolutePath}`
    } else {
      window.location.href = getXiqUrl()
    }
  }

  const handleStorageChange = (e: any) => {
    if (
      !(
        window.location.pathname.includes(PublicRoutesMap.OPEN_APP.pathId) ||
        window.location.pathname.includes(PublicRoutesMap.AUTHENTICATE_SERVICE_ACCESS.pathId) ||
        window.location.pathname.includes(PublicRoutesMap.UNAUTHORIZED_LOCATION.pathId) ||
        window.location.pathname.includes(PublicRoutesMap.UNAUTHORIZED_OS_OR_BROWSER.pathId)
      )
    ) {
      if (e.key === "userData") {
        const { role: localRole } = JSON.parse(e.oldValue)
        if (e.oldValue && !e.newValue) {
          resetUserData(localRole, window.location.pathname)
        }
        // else if ((!e.oldValue && e.newValue) || !isEqual(e.newValue, e.oldValue)) {
        //   window.location.href = `${window.location.origin}`
        // }
      }
    }
  }

  useEffect(() => {
    const userData = navigator.cookieEnabled ? localStorage.getItem("userData") : null
    if (userData && user.email === "") {
      const parsedData = JSON.parse(userData)
      dispatch(setUser(parsedData))
    }
  }, [JSON.stringify(user)])

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  return (
    <AbilityContext.Provider value={ability}>
      <JSSThemeProvider theme={theme}>
        <FlagsProvider features={featureFlags}>
          {toasterState.message && (
            <Toaster
              message={toasterState.message}
              type={toasterState.type}
              autoHide={toasterState.autoHide}
              isCloseable={toasterState.isCloseable}
              handleOk={toasterState?.handleOk}
              messageTitle={toasterState.title}
            />
          )}
          <ZtnaRoutes />
        </FlagsProvider>
      </JSSThemeProvider>
    </AbilityContext.Provider>
  )
}

export default App
